import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/menu-icon-1.svg'
import _imports_1 from '@/assets/double-arrow-right.svg'
import _imports_2 from '@/assets/menu-icon-2.svg'
import _imports_3 from '@/assets/menu-icon-3.svg'
import _imports_4 from '@/assets/menu-icon-4.svg'
import _imports_5 from '@/assets/logout-icon.svg'


const _hoisted_1 = {
  key: 0,
  src: _imports_0,
  alt: ""
}
const _hoisted_2 = {
  key: 1,
  src: _imports_1,
  alt: ""
}

import { ref } from "vue";
import { useUserStore } from "@/stores/user";
import router from "@/router";
import { useRoute } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'SideMenu',
  setup(__props) {

const showMenu = ref(false);
const userStore = useUserStore();
const route = useRoute();

const toggleMenuHandler = () => {
  showMenu.value = !showMenu.value;
};

const logoutUser = () => {
  userStore.logout();
  router.push({ name: "Login" });
};

const isSelected = (menuName: string) => {
  return menuName === route.name;
};

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["", 
      showMenu.value
        ? 'fixed min-w-full h-full top-[42px] left-0 z-50 sm:relative sm:top-[42px] sm:min-w-44 min-h-[calc(100vh-48px)]'
        : 'min-w-20 sm:min-w-20 min-h-[calc(100vh-48px)]'
    ])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(
        showMenu.value
          ? 'fixed top-[48px] py-6 h-[calc(100vh-48px)] flex flex-col gap-2 items-start justify-between'
          : 'fixed top-[48px] left-[16px] sm:left-[24px] py-6 h-[calc(100vh-48px)] flex flex-col gap-2 items-center justify-between'
      )
    }, [
      _createElementVNode("div", null, [
        _createElementVNode("div", {
          onClick: toggleMenuHandler,
          class: _normalizeClass({
            'fixed top-14 right-4 sm:relative sm:top-0 mb-1 sm:right-0 sm:ml-[132px] w-8 h-7 flex items-center justify-center cursor-pointer rounded-lg hover:bg-white':
              showMenu.value,
            'w-8 h-7 flex items-center justify-center mb-1 cursor-pointer rounded-lg hover:bg-white':
              !showMenu.value,
          })
        }, [
          (showMenu.value)
            ? (_openBlock(), _createElementBlock("img", _hoisted_1))
            : (_openBlock(), _createElementBlock("img", _hoisted_2))
        ], 2),
        _createVNode(_component_RouterLink, { to: "/" }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass({
              'h-8 flex items-center justify-start gap-1 ml-5 mb-1 px-2 py-1 cursor-pointer rounded-lg hover:bg-white':
                showMenu.value,
              'w-8 h-7 flex items-center justify-center mb-1 cursor-pointer rounded-lg hover:bg-white':
                !showMenu.value,
              'bg-white': isSelected('Home') || isSelected('ProjectDetails'),
            })
            }, [
              _cache[0] || (_cache[0] = _createElementVNode("img", {
                src: _imports_2,
                alt: "Icon"
              }, null, -1)),
              _createElementVNode("h5", {
                class: _normalizeClass(
                showMenu.value ? 'flex text-sm text-[#393840] leading-5 ' : 'hidden'
              )
              }, " Projects ", 2)
            ], 2)
          ]),
          _: 1
        }),
        _createVNode(_component_RouterLink, { to: "/downloads" }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass({
              'h-8 flex items-center justify-start gap-1 ml-5 mb-1 px-2 py-1 cursor-pointer rounded-lg hover:bg-white':
                showMenu.value,
              'w-8 h-7 flex items-center justify-center mb-1 cursor-pointer rounded-lg hover:bg-white':
                !showMenu.value,
              'bg-white': isSelected('Downloads'),
            })
            }, [
              _cache[1] || (_cache[1] = _createElementVNode("img", {
                src: _imports_3,
                alt: "Icon"
              }, null, -1)),
              _createElementVNode("h5", {
                class: _normalizeClass(
                showMenu.value ? 'flex text-sm text-[#393840] leading-5 ' : 'hidden'
              )
              }, " My downloads ", 2)
            ], 2)
          ]),
          _: 1
        }),
        _createVNode(_component_RouterLink, { to: "/help" }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass({
              'h-8 flex items-center justify-start gap-1 ml-5 mb-1 px-2 py-1 cursor-pointer rounded-lg hover:bg-white':
                showMenu.value,
              'w-8 h-7 flex items-center justify-center mb-1 cursor-pointer rounded-lg hover:bg-white':
                !showMenu.value,
              'bg-white': isSelected('Help'),
            })
            }, [
              _cache[2] || (_cache[2] = _createElementVNode("img", {
                src: _imports_4,
                alt: "Icon"
              }, null, -1)),
              _createElementVNode("h5", {
                class: _normalizeClass(
                showMenu.value ? 'flex text-sm text-[#393840] leading-5 ' : 'hidden'
              )
              }, " Get help ", 2)
            ], 2)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(
          showMenu.value
            ? 'h-8 flex items-center justify-start gap-2 ml-5 px-2 py-1 cursor-pointer rounded-lg hover:bg-white'
            : 'w-8 h-7 flex items-center justify-center cursor-pointer rounded-lg hover:bg-white'
        ),
        onClick: logoutUser
      }, [
        _cache[3] || (_cache[3] = _createElementVNode("img", {
          src: _imports_5,
          alt: "Icon"
        }, null, -1)),
        _createElementVNode("h5", {
          class: _normalizeClass(
            showMenu.value ? 'flex text-sm text-[#393840] leading-5 ' : 'hidden'
          )
        }, " Logout ", 2)
      ], 2)
    ], 2)
  ], 2))
}
}

})