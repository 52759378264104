<script setup lang="ts">
import MainLayout from "@/layouts/MainLayout.vue";
import type {
  ProjectDetail,
  ProjectComment,
  ProjectDocument,
  InstallationTermin,
} from "@/types";
import { onBeforeMount, ref } from "vue";
import ProjectInstallationTermin from "@/components/Project/ProjectInstallationTermin.vue";
import InstallationTerminsSection from "@/components/Project/InstallationTerminsSection.vue";
import InfoSection from "@/components/Project/InfoSection.vue";
import CommentSection from "@/components/Project/CommentsSection.vue";
import DocumentsSection from "@/components/Project/DocumentsSection.vue";
import { useRoute } from "vue-router";
import { useSingleProjectStore } from "@/stores/singleProject";
import { statusList } from "@/utils/constants";

const singleProjectStore = useSingleProjectStore();
import client from "@/utils/axios";
import router from "@/router";
import { toast } from "vue3-toastify";

const id = useRoute().params.id;

const project = ref<ProjectDetail | null>(null);
const comments = ref<ProjectComment[]>([]);
const documents = ref<ProjectDocument[]>([]);

const installationTerminsToggle = ref(true);
const documentsToggle = ref(true);
const downloadsToggle = ref(true);

const projectLoading = ref(true);
const commentsLoading = ref(true);
const documentsLoading = ref(true);

const uploadingDocument = ref(false);

onBeforeMount(async () => {
  // Fetch project details
  project.value = await singleProjectStore.getProject(id as string);
  projectLoading.value = false;

  // Fetch project documents
  documents.value = await singleProjectStore.fetchSingleProjectDocuments(
    id as string
  );
  documentsLoading.value = false;
  // Fetch project installation dates
  comments.value = await singleProjectStore.fetchSingleProjectComments(
    id as string
  );
  commentsLoading.value = false;
});

const selectMenuHandler = (toggleType: string) => {
  switch (toggleType) {
    case "installation-termins":
      installationTerminsToggle.value = !installationTerminsToggle.value;
      break;
    case "documents":
      documentsToggle.value = !documentsToggle.value;
      break;
    case "downloads":
      downloadsToggle.value = !downloadsToggle.value;
      break;
  }
};

const selectedTermin = ref<InstallationTermin | null>(null);
const isTerminModal = ref(false);

const terminModalHandler = (termin: InstallationTermin) => {
  isTerminModal.value = true;
  selectedTermin.value = termin;
};

const refreshComments = async () => {
  comments.value = await singleProjectStore.fetchSingleProjectComments(
    id as string
  );
};

const refreshDocuments = async () => {
  documents.value = await singleProjectStore.fetchSingleProjectDocuments(
    id as string
  );
};

const uploadFileHandler = async (file: File) => {
  uploadingDocument.value = true;
  // send file as multipart/form-data
  const formData = new FormData();
  formData.append("file", file);
  formData.append("id", id as string);

  const response = await client.post(
    `/projects/${id}/documents/upload`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  if (response.status === 200) {
    toast.success("File uploaded successfully");
    refreshDocuments();
  } else {
    toast.error("Something went wrong. Please try again.");
  }
  uploadingDocument.value = false;
};
</script>

<template>
  <MainLayout>
    <div class="p-4 md:p-6">
      <!-- Top side -->
      <div class="mb-5 sm:mb-7">
        <!-- Project title -->
        <div class="flex flex-col gap-2 sm:flex-row sm:items-center">
          <div class="flex items-center gap-1 sm:gap-2">
            <div
              class="min-w-6 min-h-6 border-[2px] border-black rounded-lg flex items-center justify-center mr-1 cursor-pointer"
              @click="router.push({ name: 'Home' })"
            >
              <img src="@/assets/project-details-icon.svg" alt="Icon" />
            </div>
            <h2
              class="text-xl sm:text-2xl text-wrap font-semibold text-[#393840]"
            >
              {{ project ? project.name : "" }}
            </h2>
          </div>
          <div class="flex flex-col items-start sm:flex-row gap-2">
            <span
              :class="`px-2 py-[2px] text-center rounded-lg text-sm sm:text-base text-[#393840] font-normal`"
              :style="{
                backgroundColor: `${
                  statusList.find((status) => status.id === project?.stage?.id)
                    ?.color
                }`,
              }"
            >
              {{ project ? project.stage.name : "" }}
            </span>
          </div>
        </div>
      </div>
      <!-- Content -->
      <div class="grid grid-cols-1 gap-3 lg:grid-cols-2">
        <!-- Left side -->
        <div class="w-full lg:h-[85vh] overflow-auto">
          <!-- Installation terms -->
          <InstallationTerminsSection
            :loading="projectLoading"
            :installation-termins="project?.installationTermins"
            @toggle-termins="selectMenuHandler('installation-termins')"
            @termin-modal-handler="terminModalHandler"
          />
          <!-- Project details -->
          <InfoSection
            :loading="projectLoading"
            :project-name="project?.name || ''"
            :home-owner="project?.homeowner_name || ''"
            :homeowner-number="project?.homeowner_phone || ''"
            :project-installation-dates="project?.installationTermins || []"
            :address="project?.address"
            :stage="project?.stage"
          />
          <!-- Documents -->
          <DocumentsSection
            :documents="documents"
            :documents-toggle="documentsToggle"
            :loading="documentsLoading"
            :uploading-document="uploadingDocument"
            :project-name="project?.name"
            :project-id="id + ''"
            @toggle-documents="selectMenuHandler('documents')"
            @upload-document="uploadFileHandler"
          />

          <CommentSection
            v-if="project?.source !== 'salesforce'"
            :project-id="(id as string)"
            :comments="comments"
            :loading="commentsLoading"
            @refresh-comments="refreshComments"
          />
        </div>
        <!-- Right side -->
        <div
          v-if="selectedTermin"
          class="w-full h-full px-[14px] py-[10px] border-[2px] border-[#A7A5AE] rounded-lg"
        >
          <div class="overflow-hidden w-full">
            <ProjectInstallationTermin
              :installation-termin="selectedTermin"
              :active-termin-id="selectedTermin?.id"
              @on-close="selectedTermin = null"
            />
          </div>
        </div>
      </div>
    </div>
  </MainLayout>
</template>
