import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import LoginView from "@/views/Login/LoginView.vue";
import ProjectDetails from "@/views/Projects/ProjectDetails.vue";
import DownloadsView from "@/views/Downloads/DownloadsView.vue";
import GetHelpView from "@/views/GetHelp/GetHelpVIew.vue";
import { useUserStore } from "@/stores/user";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/projects/:id",
    name: "ProjectDetails",
    component: ProjectDetails,
    props: true,
  },
  {
    path: "/downloads/",
    name: "Downloads",
    component: DownloadsView,
    props: true,
  },
  {
    path: "/help/",
    name: "Help",
    component: GetHelpView,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const userStore = useUserStore();

  if (!userStore.accessToken && to.name !== "Login") {
    next({ name: "Login", query: { redirect: to.fullPath } });
  } else {
    if (userStore.accessToken && to.name === "Login") {
      next({ name: "Home" });
    }
  }
  next();
});

export default router;
