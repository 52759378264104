import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/open-in-new-tab.svg'
import _imports_1 from '@/assets/no-results/documents.png'
import _imports_2 from '@/assets/upload-icon.svg'
import _imports_3 from '@/assets/dots-icon.svg'
import _imports_4 from '@/assets/download-icon.svg'


const _hoisted_1 = { class: "bg-white rounded-xl px-[12px] py-[14px] mt-6" }
const _hoisted_2 = { class: "shadow-md rounded-lg" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "flex items-center justify-between cursor-pointer break-words gap-2" }
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "flex gap-3" }
const _hoisted_9 = ["href"]
const _hoisted_10 = ["value"]
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "" }
const _hoisted_13 = {
  key: 0,
  class: "flex items-center flex-wrap gap-3 py-3 px-4 mt-6"
}
const _hoisted_14 = ["disabled"]
const _hoisted_15 = {
  key: 0,
  class: "flex gap-2"
}
const _hoisted_16 = {
  key: 1,
  class: "flex gap-2"
}
const _hoisted_17 = ["disabled"]
const _hoisted_18 = {
  key: 0,
  class: "flex gap-2"
}
const _hoisted_19 = {
  key: 1,
  class: "flex gap-2"
}

import "/custom.css";
import { ref } from "vue";
import type { ProjectDocument } from "@/types";
import RowLoader from "../Loaders/RowLoader.vue";
import client from "@/utils/axios";
import { toast } from "vue3-toastify";

type Props = {
  documents: ProjectDocument[] | undefined;
  loading: boolean;
  uploadingDocument: boolean;
  documentsToggle: boolean;
  projectName?: string | undefined;
  projectId?: string;
};

type Emits = {
  (event: "toggleDocuments"): void;
  (event: "uploadDocument", file: File): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'DocumentsSection',
  props: {
    documents: {},
    loading: { type: Boolean },
    uploadingDocument: { type: Boolean },
    documentsToggle: { type: Boolean },
    projectName: {},
    projectId: {}
  },
  emits: ["toggleDocuments", "uploadDocument"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emits = __emit;
const selectedDocument = ref([]);
const uploadFile = ref<HTMLElement | null>(null);

const downloadingDocument = ref(false);

const uploadFileInitiate = () => {
  if (uploadFile.value) {
    uploadFile.value.click();
  }
};

const uploadFileHandler = (e: Event) => {
  const files = (e.target as HTMLInputElement).files;
  if (files) {
    emits("uploadDocument", files[0]);
  }
};

const downloadDocumentHandler = async () => {
  let documentsForDownload: any = [];
  if (selectedDocument.value.length > 0) {
    documentsForDownload = [...selectedDocument.value];
  } else {
    documentsForDownload = props.documents?.map(
      (item: ProjectDocument) => item.id
    );
  }
  downloadingDocument.value = true;
  const response = await client.post("/projects/prepare-documents", {
    data: {
      project: props.projectName ? props.projectName : "",
      documents: documentsForDownload,
      projectId: props.projectId,
    },
  });

  if (response && response.status === 200) {
    toast.success(
      "Documents are being prepared for download. You can check progress on downloads page."
    );
  }

  if (response && response.status >= 400) {
    toast.error(response.statusText);
  }
  selectedDocument.value = [];
  downloadingDocument.value = false;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('toggleDocuments')))
      }, _cache[2] || (_cache[2] = [
        _createElementVNode("h5", { class: "bg-[#E3E1EA] rounded-t-lg px-3 py-3 cursor-pointer text-base font-semibold text-[#393840]" }, " Documents ", -1)
      ])),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Math.floor(Math.random() * 6) + 3, (index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "px-4 py-3"
              }, [
                _createVNode(RowLoader)
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, [
        (props.documentsToggle)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_ctx.documents && _ctx.documents?.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.documents, (document, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: "px-4 py-3 hover:bg-[#F1F0F5] duration-200"
                      }, [
                        _createElementVNode("label", _hoisted_6, [
                          _createElementVNode("a", {
                            href: document.url,
                            class: "text-sm sm:text-base font-normal text-[#616068] hover:underline",
                            target: "_blank"
                          }, _toDisplayString(document ? document.name : ""), 9, _hoisted_7),
                          _createElementVNode("div", _hoisted_8, [
                            _createElementVNode("a", {
                              href: document.url,
                              target: "_blank"
                            }, _cache[3] || (_cache[3] = [
                              _createElementVNode("img", {
                                src: _imports_0,
                                alt: "open icon",
                                class: "hover:-translate-y-0.5 hover:translate-x-0.5 duration-200"
                              }, null, -1)
                            ]), 8, _hoisted_9),
                            _withDirectives(_createElementVNode("input", {
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selectedDocument).value = $event)),
                              type: "checkbox",
                              value: document.id
                            }, null, 8, _hoisted_10), [
                              [_vModelCheckbox, selectedDocument.value]
                            ])
                          ])
                        ])
                      ]))
                    }), 128))
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[4] || (_cache[4] = [
                    _createElementVNode("img", {
                      src: _imports_1,
                      alt: "No Documents",
                      class: "mx-auto"
                    }, null, -1)
                  ]))),
              _createElementVNode("div", _hoisted_12, [
                (!_ctx.loading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      _createElementVNode("button", {
                        class: "w-full sm:w-auto flex items-center justify-center gap-2 sm:gap-3 border-2 text-xs sm:text-sm font-semibold text-[#393840] border-black bg-white rounded-lg py-[8px] sm:py-[11px] px-[14px] sm:px-[18px] cursor-pointer hover:bg-[#A3B1FF] duration-200 disabled:hover:bg-white disabled:cursor-not-allowed",
                        disabled: _ctx.uploadingDocument || downloadingDocument.value,
                        onClick: uploadFileInitiate
                      }, [
                        (!_ctx.uploadingDocument)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_15, _cache[5] || (_cache[5] = [
                              _createElementVNode("img", {
                                class: "w-4 h-4 sm:w-5 sm:h-5",
                                src: _imports_2,
                                alt: "upload icon"
                              }, null, -1),
                              _createTextVNode(" Upload Document ")
                            ])))
                          : (_openBlock(), _createElementBlock("span", _hoisted_16, _cache[6] || (_cache[6] = [
                              _createElementVNode("img", {
                                src: _imports_3,
                                alt: "loading icon"
                              }, null, -1),
                              _createTextVNode(" Uploading... ")
                            ])))
                      ], 8, _hoisted_14),
                      _createElementVNode("input", {
                        ref_key: "uploadFile",
                        ref: uploadFile,
                        type: "file",
                        class: "hidden",
                        onChange: uploadFileHandler
                      }, null, 544),
                      (_ctx.documents && _ctx.documents.length > 0)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            class: "w-full sm:w-auto flex items-center justify-center gap-2 sm:gap-3 border-2 text-xs sm:text-sm font-semibold text-[#393840] border-black bg-white rounded-lg py-[8px] sm:py-[11px] px-[14px] sm:px-[18px] cursor-pointer hover:bg-[#A3B1FF] duration-200 disabled:hover:bg-white disabled:cursor-not-allowed",
                            disabled: _ctx.uploadingDocument || downloadingDocument.value,
                            onClick: downloadDocumentHandler
                          }, [
                            (!downloadingDocument.value)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_18, _cache[7] || (_cache[7] = [
                                  _createElementVNode("img", {
                                    class: "w-4 h-4 sm:w-5 sm:h-5",
                                    src: _imports_4,
                                    alt: "download icon"
                                  }, null, -1),
                                  _createTextVNode(" Download ")
                                ])))
                              : (_openBlock(), _createElementBlock("span", _hoisted_19, _cache[8] || (_cache[8] = [
                                  _createElementVNode("img", {
                                    src: _imports_3,
                                    alt: "loading icon"
                                  }, null, -1),
                                  _createTextVNode(" Downloading... ")
                                ])))
                          ], 8, _hoisted_17))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})