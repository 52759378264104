import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/logo.svg'
import _imports_1 from '@/assets/login-arrow.svg'
import _imports_2 from '@/assets/login-arrow-white.svg'


const _hoisted_1 = { class: "w-full h-screen bg-[url('@/assets/login-background.png')] bg-center bg-no-repeat bg-cover flex justify-center pt-32" }
const _hoisted_2 = { class: "w-full px-7 sm:w-[420px]" }
const _hoisted_3 = { class: "flex flex-col mb-4" }
const _hoisted_4 = { class: "flex flex-col mb-8" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = {
  key: 0,
  class: "flex gap-3"
}
const _hoisted_7 = {
  key: 0,
  src: _imports_1,
  alt: "Arrow icon"
}
const _hoisted_8 = {
  key: 1,
  src: _imports_2,
  alt: "Arrow icon"
}

import { ref } from "vue";
import { useUserStore } from "@/stores/user";
import router from "@/router";
import SpinnerLoader from "@/components/Loaders/SpinnerLoader.vue";
import { toast } from "vue3-toastify";


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginView',
  setup(__props) {

const email = ref<string>("");
const password = ref<string>("");
const userStore = useUserStore();

const submitting = ref(false);
const hover = ref(false);

const loginUser = async (e: Event) => {
  e.preventDefault();
  if (!email.value || !password.value || submitting.value) {
    toast.error("Invalid credentials");
    return;
  }

  submitting.value = true;

  await userStore.login(email.value, password.value);

  if (userStore.getAccessToken) {
    const redirectPath =
      (router.currentRoute.value.query.redirect as string) || "/";

    router.push(redirectPath);
  } else {
    toast.error("Invalid credentials");
  }

  submitting.value = false;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[7] || (_cache[7] = _createStaticVNode("<div class=\"mb-16\"><img src=\"" + _imports_0 + "\" alt=\"Logo\"></div><div class=\"mb-8\"><h2 class=\"font-bold text-3xl text-white pb-1\"> Login to your Account </h2><h5 class=\"text-white text-xs\"> Find the details and statuses of all your projects </h5></div>", 2)),
        _createElementVNode("form", { onSubmit: loginUser }, [
          _createElementVNode("div", _hoisted_3, [
            _cache[4] || (_cache[4] = _createElementVNode("label", {
              for: "email",
              class: "text-white text-sm pb-[2px] font-semibold"
            }, "Email", -1)),
            _withDirectives(_createElementVNode("input", {
              id: "email",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((email).value = $event)),
              class: "text-sm text-white bg-transparent border border-white rounded-lg py-[12px] px-[10px] outline-none",
              type: "email"
            }, null, 512), [
              [_vModelText, email.value]
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _cache[5] || (_cache[5] = _createElementVNode("label", {
              for: "password",
              class: "text-white text-sm pb-[2px] font-semibold"
            }, "Password", -1)),
            _withDirectives(_createElementVNode("input", {
              id: "password",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((password).value = $event)),
              class: "text-sm text-white bg-transparent border border-white rounded-lg py-[12px] px-[10px] outline-none",
              type: "password"
            }, null, 512), [
              [_vModelText, password.value]
            ])
          ]),
          _createElementVNode("button", {
            class: "w-full flex items-center justify-center gap-3 text-[#393840] text-lg font-semibold bg-white border-0 rounded-lg py-3 px-[18px] hover:bg-[#0A0564] hover:text-white duration-300 disabled:hover:bg-white disabled:hover:text-[#393840] disabled:cursor-not-allowed",
            type: "submit",
            disabled: submitting.value,
            onMouseenter: _cache[2] || (_cache[2] = ($event: any) => (hover.value = submitting.value ? false : true)),
            onMouseleave: _cache[3] || (_cache[3] = ($event: any) => (hover.value = false))
          }, [
            (!submitting.value)
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                  (!hover.value)
                    ? (_openBlock(), _createElementBlock("img", _hoisted_7))
                    : _createCommentVNode("", true),
                  (hover.value)
                    ? (_openBlock(), _createElementBlock("img", _hoisted_8))
                    : _createCommentVNode("", true),
                  _cache[6] || (_cache[6] = _createTextVNode(" Login "))
                ]))
              : (_openBlock(), _createBlock(SpinnerLoader, { key: 1 }))
          ], 40, _hoisted_5)
        ], 32)
      ])
    ])
  ]))
}
}

})