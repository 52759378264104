<script setup lang="ts">
import { computed, defineProps } from "vue";
import { Status } from "@/types";
import InlineLoader from "../Loaders/InlineLoader.vue";
import { formatStartEndDate } from "@/utils/format-date";
import { statusList } from "@/utils/constants";
import { InstallationTermin } from "@/types";

type Props = {
  loading: boolean;
  projectName: string;
  homeOwner: string | null;
  homeownerNumber: string;
  projectInstallationDates: Array<InstallationTermin>;
  address: string | undefined;
  stage: Status | undefined;
};

const props = defineProps<Props>();

let startDate = "1970-01-01T00:00:00+00:00";
let endDate = "9999-12-31T23:59:59+00:00";

let installationDates = computed(() => {
  if (props.projectInstallationDates?.length) {
    props.projectInstallationDates.forEach((installationDate) => {
      if (new Date(installationDate.start) > new Date(startDate)) {
        startDate = installationDate.start;
      }

      if (new Date(installationDate.end) < new Date(endDate)) {
        endDate = installationDate.end;
      }
    });
    return formatStartEndDate(startDate, endDate);
  }
  return "";
});
</script>

<template>
  <div class="mt-6 bg-white rounded-lg px-[12px] py-[12px]">
    <h4 class="pb-6 text-base font-semibold text-[#393840]">Project details</h4>
    <ul class="flex flex-col gap-3">
      <li
        class="text-sm sm:text-base font-normal text-[#393840] flex items-center gap-3"
      >
        Project name:
        <span v-if="loading">
          <InlineLoader />
        </span>
        <span v-else>{{ projectName }}</span>
      </li>
      <li
        class="text-sm sm:text-base font-normal text-[#393840] flex items-center gap-3"
      >
        Site owner:
        <span v-if="loading">
          <InlineLoader />
        </span>
        <div
          v-else
          class="text-sm sm:text-base font-normal text-[#393840] flex items-center gap-3"
        >
          {{ homeOwner ? homeOwner : "" }}
        </div>
      </li>
      <li
        class="text-sm sm:text-base font-normal text-[#393840] flex items-center gap-3"
      >
        Phone:
        <span v-if="loading">
          <InlineLoader />
        </span>
        <span v-else>{{ homeownerNumber }}</span>
      </li>
      <li
        class="flex items-center flex-wrap gap-1 text-sm sm:text-base font-normal text-[#393840]"
      >
        Installation dates:
        <span v-if="loading">
          <InlineLoader />
        </span>
        <span v-else>
          {{ installationDates || "No installation dates" }}
        </span>
      </li>
      <li
        class="text-sm sm:text-base font-normal text-[#393840] flex items-center gap-3"
      >
        Address:
        <span v-if="loading">
          <InlineLoader />
        </span>
        <span v-else>{{ address || "" }}</span>
      </li>
      <li
        class="text-sm sm:text-base font-normal text-[#393840] flex items-center gap-3"
      >
        Project status:
        <span v-if="loading">
          <InlineLoader />
        </span>
        <span
          v-else
          class="px-2 rounded-lg text-sm text-center"
          :style="{
            backgroundColor: `${
              statusList.find((status) => status.id === stage?.id)?.color
            }`,
          }"
        >
          {{ stage?.name }}
        </span>
      </li>
    </ul>
  </div>
</template>
