import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/project-details-icon.svg'


const _hoisted_1 = { class: "p-4 md:p-6" }
const _hoisted_2 = { class: "mb-5 sm:mb-7" }
const _hoisted_3 = { class: "flex flex-col gap-2 sm:flex-row sm:items-center" }
const _hoisted_4 = { class: "flex items-center gap-1 sm:gap-2" }
const _hoisted_5 = { class: "text-xl sm:text-2xl text-wrap font-semibold text-[#393840]" }
const _hoisted_6 = { class: "flex flex-col items-start sm:flex-row gap-2" }
const _hoisted_7 = { class: "grid grid-cols-1 gap-3 lg:grid-cols-2" }
const _hoisted_8 = { class: "w-full lg:h-[85vh] overflow-auto" }
const _hoisted_9 = {
  key: 0,
  class: "w-full h-full px-[14px] py-[10px] border-[2px] border-[#A7A5AE] rounded-lg"
}
const _hoisted_10 = { class: "overflow-hidden w-full" }

import MainLayout from "@/layouts/MainLayout.vue";
import type {
  ProjectDetail,
  ProjectComment,
  ProjectDocument,
  InstallationTermin,
} from "@/types";
import { onBeforeMount, ref } from "vue";
import ProjectInstallationTermin from "@/components/Project/ProjectInstallationTermin.vue";
import InstallationTerminsSection from "@/components/Project/InstallationTerminsSection.vue";
import InfoSection from "@/components/Project/InfoSection.vue";
import CommentSection from "@/components/Project/CommentsSection.vue";
import DocumentsSection from "@/components/Project/DocumentsSection.vue";
import { useRoute } from "vue-router";
import { useSingleProjectStore } from "@/stores/singleProject";
import { statusList } from "@/utils/constants";

import client from "@/utils/axios";
import router from "@/router";
import { toast } from "vue3-toastify";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectDetails',
  setup(__props) {

const singleProjectStore = useSingleProjectStore();
const id = useRoute().params.id;

const project = ref<ProjectDetail | null>(null);
const comments = ref<ProjectComment[]>([]);
const documents = ref<ProjectDocument[]>([]);

const installationTerminsToggle = ref(true);
const documentsToggle = ref(true);
const downloadsToggle = ref(true);

const projectLoading = ref(true);
const commentsLoading = ref(true);
const documentsLoading = ref(true);

const uploadingDocument = ref(false);

onBeforeMount(async () => {
  // Fetch project details
  project.value = await singleProjectStore.getProject(id as string);
  projectLoading.value = false;

  // Fetch project documents
  documents.value = await singleProjectStore.fetchSingleProjectDocuments(
    id as string
  );
  documentsLoading.value = false;
  // Fetch project installation dates
  comments.value = await singleProjectStore.fetchSingleProjectComments(
    id as string
  );
  commentsLoading.value = false;
});

const selectMenuHandler = (toggleType: string) => {
  switch (toggleType) {
    case "installation-termins":
      installationTerminsToggle.value = !installationTerminsToggle.value;
      break;
    case "documents":
      documentsToggle.value = !documentsToggle.value;
      break;
    case "downloads":
      downloadsToggle.value = !downloadsToggle.value;
      break;
  }
};

const selectedTermin = ref<InstallationTermin | null>(null);
const isTerminModal = ref(false);

const terminModalHandler = (termin: InstallationTermin) => {
  isTerminModal.value = true;
  selectedTermin.value = termin;
};

const refreshComments = async () => {
  comments.value = await singleProjectStore.fetchSingleProjectComments(
    id as string
  );
};

const refreshDocuments = async () => {
  documents.value = await singleProjectStore.fetchSingleProjectDocuments(
    id as string
  );
};

const uploadFileHandler = async (file: File) => {
  uploadingDocument.value = true;
  // send file as multipart/form-data
  const formData = new FormData();
  formData.append("file", file);
  formData.append("id", id as string);

  const response = await client.post(
    `/projects/${id}/documents/upload`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  if (response.status === 200) {
    toast.success("File uploaded successfully");
    refreshDocuments();
  } else {
    toast.error("Something went wrong. Please try again.");
  }
  uploadingDocument.value = false;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MainLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", {
                class: "min-w-6 min-h-6 border-[2px] border-black rounded-lg flex items-center justify-center mr-1 cursor-pointer",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push({ name: 'Home' })))
              }, _cache[4] || (_cache[4] = [
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: "Icon"
                }, null, -1)
              ])),
              _createElementVNode("h2", _hoisted_5, _toDisplayString(project.value ? project.value.name : ""), 1)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("span", {
                class: _normalizeClass(`px-2 py-[2px] text-center rounded-lg text-sm sm:text-base text-[#393840] font-normal`),
                style: _normalizeStyle({
                backgroundColor: `${
                  _unref(statusList).find((status) => status.id === project.value?.stage?.id)
                    ?.color
                }`,
              })
              }, _toDisplayString(project.value ? project.value.stage.name : ""), 5)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(InstallationTerminsSection, {
              loading: projectLoading.value,
              "installation-termins": project.value?.installationTermins,
              onToggleTermins: _cache[1] || (_cache[1] = ($event: any) => (selectMenuHandler('installation-termins'))),
              onTerminModalHandler: terminModalHandler
            }, null, 8, ["loading", "installation-termins"]),
            _createVNode(InfoSection, {
              loading: projectLoading.value,
              "project-name": project.value?.name || '',
              "home-owner": project.value?.homeowner_name || '',
              "homeowner-number": project.value?.homeowner_phone || '',
              "project-installation-dates": project.value?.installationTermins || [],
              address: project.value?.address,
              stage: project.value?.stage
            }, null, 8, ["loading", "project-name", "home-owner", "homeowner-number", "project-installation-dates", "address", "stage"]),
            _createVNode(DocumentsSection, {
              documents: documents.value,
              "documents-toggle": documentsToggle.value,
              loading: documentsLoading.value,
              "uploading-document": uploadingDocument.value,
              "project-name": project.value?.name,
              "project-id": _unref(id) + '',
              onToggleDocuments: _cache[2] || (_cache[2] = ($event: any) => (selectMenuHandler('documents'))),
              onUploadDocument: uploadFileHandler
            }, null, 8, ["documents", "documents-toggle", "loading", "uploading-document", "project-name", "project-id"]),
            (project.value?.source !== 'salesforce')
              ? (_openBlock(), _createBlock(CommentSection, {
                  key: 0,
                  "project-id": (_unref(id) as string),
                  comments: comments.value,
                  loading: commentsLoading.value,
                  onRefreshComments: refreshComments
                }, null, 8, ["project-id", "comments", "loading"]))
              : _createCommentVNode("", true)
          ]),
          (selectedTermin.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(ProjectInstallationTermin, {
                    "installation-termin": selectedTermin.value,
                    "active-termin-id": selectedTermin.value?.id,
                    onOnClose: _cache[3] || (_cache[3] = ($event: any) => (selectedTermin.value = null))
                  }, null, 8, ["installation-termin", "active-termin-id"])
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}
}

})